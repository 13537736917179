import React from "react";
import { Link } from "gatsby";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";
import { currentYear } from "../../components/Helpers";
import ImgScreenshot from "../../components/ImgScreenshot.js";
import ImgContainerFixed from "../../components/ImgContainerFixed";
import H from "../../components/Headline";

const breadCrumbLevels = {
  Home: "/",
  // Blog: "/blog",
  "Google Analytics Beratung": "/de/google-analytics-beratung",
  "Google Analytics einrichten": "/de/google-analytics-einrichten"
};

// Hreflang data
const alternateLangs = [
  {
    hreflang: "de",
    href: "/de/google-analytics-einrichten"
  },
  {
    hreflang: "en",
    href: "/en/google-analytics-setup"
  },
  {
    hreflang: "x-default",
    href: "/en/google-analytics-setup"
  }
];

const GoogleAnalyticsEinrichten = props => (
  <Layout location={props.location} alternateLangs={alternateLangs}>

    <React.Fragment>
      <SEO
        title={`Google Analytics einrichten und einstellen [${currentYear} Anleitung]`}
        description="Anleitung um Google Analytics einzurichten und das Tracking für die Webseite einzustellen. Zielvorhaben, Events und Filter. 2019 Tutorial vom zertifizierten Google Analytics Consultant."
        lang="de"
        canonical="/de/google-analytics-einrichten"
        image="google-analytics-setup-realtime-report-hero"
        alternateLangs={alternateLangs}
      />
      <MainContent article>
        <ImgScreenshot
          src="google-analytics-einrichten/google-analytics-setup-realtime-report-hero.png"
          alt="man working on google analytics setup with laptop"
        />
        <Breadcrumb breadCrumbLevels={breadCrumbLevels} />
        <H as="h1">Google Analytics einrichten</H>
        <p>
          Eine der ersten Aufgaben bei einer neuen Webseite ist das <em>Einrichten von Google Analytics</em>. Nachdem
          man so viel Zeit und Gedanken in den Bau der Webseite investiert hat, möchte man das Nutzerverhalten messen
          und analysieren.
        </p>
        <ul>
          <li>Wie performen die geschriebenen Texte?</li>
          <li>Welche Produktkategorie ist die beliebteste?</li>
          <li>Was hat die letzte Facebook Kampagne eingebracht?</li>
          <li>Schaut sich den Film, den ich produziert habe, überhaupt jemand an?</li>
          <li>Gibt es Seiten, die besonders schlecht abschneiden?</li>
          <li>Werden Inhalte in den sozialen Netzwerken geteilt?</li>
          <li>Verlassen Besucher die Seite während sie gerade im Checkout -bereich sind?</li>
        </ul>
        <p>
          Anfängliche Annahmen über Nutzerverhalten und den vermeintlichen Erfolg von Kampagnen sollten überprüft
          werden. Entscheidungen über Änderung von Inhalten oder Budgets für Kampagnen sollten möglichst auf Basis von
          Daten und nicht Bauchgefühl getroffen werden.
        </p>
        <p>
          Daher ist es von essenzieller Wichtigkeit Google Analytics einzurichten und professionel einzustellen. Es
          sollten die richtigen Kennzahlen eingesammelt und analysiert werden, sodass man Entscheidungen treffen kann,
          um die Webseite zu verbessern.
        </p>
        <H as="h2">Arten von Google Analytics Setups</H>
        <p>
          Der Typ von Webseite, für den es gilt Google Analytics einzurichten, ist ein entscheidender Faktor:
          <br />
          Hast Du einen Onlineshop oder verkaufst ein paar Produkte?
          <br />
          Dann sollte E-Commerce Tracking eingerichtet werden. Durch den stärkeren Fokus auf Produkte und
          Transaktionen können hier schnell wichtige Erkenntnisse gewonnen werden.
        </p>
        <p>
          Wenn Du wiederum eine sehr Text-basierte Seite betreibst, ist ein herkömmliches Google Analytics Setup die
          richtige Wahl.
          <br />
          Du kannst Interaktionen und clicks mit <Link to="/de/event-tracking">Events (Ereignissen) tracken</Link> und
          die Hauptziele mit{" "}
          <Link to="/de/google-analytics-einrichten#google-analytics-zielvorhaben-einrichten">
            Google Analytics Zielvorhaben
          </Link>{" "}
          überschauen.
        </p>
        <p>
          E-Commerce Tracking benötigt mehr Daten über die bereitgestellten Produkte und Transaktionen. Die
          Implementierung ist etwas komplexer, da die Infos mit spezifischen JavaScript in einem{" "}
          <Link to="/de/data-layer">Data Layer</Link>
          synchronisiert werden müssen. Ein Data Layer ist eigentlich nur eine einheitlicher Speicher für die Daten
          auf jeder Unterseite, die man tracken möchte. Google Analytics nutzt den Data Layer dann automatisch, wenn
          Daten zur Verfügung stehen.
        </p>
        <p>
          Keine Panik, wenn Du jetzt bemerkst, dass Du ein kompliziertes E-Commerce tracking Setup haben möchtest. Du
          kannst alle E-Commerce Feinheiten im Nachhinein implementieren und dann aktivieren. Bis dahin bleiben
          E-Commerce Berichte in Google Analytics einfach leer, aber alle anderen Berichte funktionieren einwandfrei.
        </p>
        <H as="h2">Google Analytics Tracking Code</H>
        <p>
          Um den Google Analytics tracking code zu bekommen, kann man einfach{" "}
          <a href="https://analytics.google.com/analytics/web/provision/?hl=de">analytics.google.com</a> besuchen und
          muss nur den Schritten folgen.
        </p>
        <ImgScreenshot
          src="google-analytics-einrichten/google-analytics-tracking-code-bekommen.png"
          alt="Google Tag Manager Konto erstellen"
        />
        <ul>
          <li>
            Klick auf <strong>registrieren</strong> und wähle einen Namen für’s Google Analytics Konto, z.B. die
            Webseitenadresse. Dann noch URL, Branche und Zeitzone angeben. Fertig.
          </li>
          <li>
            Ich würde empfehlen alle Häkchen fürs Teilen von Daten zu entfernen, wenn Du nicht genau weißt was Du
            tust. Das bedeutet allerdings, dass Demografie- und Interessenberichte in Google Analytics leer bleiben.
            Google muss für diese Berichte die Nutzerdaten mit Dritten teilen. Falls Du die Berichte nutzen willst,
            musst Du wahrscheinlich Deine Datenschutzerklärung updaten.
          </li>
          <li>
            Dann unten auf “Tracking-ID abrufen” klicken, und schon bekommen wir den Tracking code (z.B.{" "}
            <em>UA- 141112345-1</em> ). Zusammen mit dem Tracking Code, erhalten wir auch das gesamte{" "}
            <strong>Google Analytics Tag</strong>. Das Tag muss nun auf jeder Seite der Website im Quelltext
            implementiert werden.
          </li>
        </ul>
        <H as="h2">Google Analytics Implementieren</H>
        <p>
          Es gilt zwischen zwei <strong>Implementierungen für Google Analytics</strong> zu unterscheiden:
        </p>
        <ol>
          <li>
            Du fügst das Google Analytics Tag in den Quelltext auf allen Seiten ein. Abhängig von der Webseite, kannst
            Du das Tag ggf. in einem gemeinsamen Komponenten aller Seiten installieren. Die gängigen Komponenten
            hierfür sind der Header, die Navigation oder der Footer.
            <br />
            <strong>Ich rate von dieser Art der Implementierung ab</strong>, außer es soll nicht viel Wert auf’s
            Analytics Setup gelegt werden. Die Implementierungen verläuft meist schneller und ist später
            anpassungsfähiger, wenn man ein <Link to="/de/tag-management">Tag Management System</Link> benutzt (siehe 2.). Daher macht es Sinn noch ein
            Tool mit ins Setup einzubauen.
          </li>
        </ol>
        <ol start="2">
          <li>
            Du benutzt ein Tag Management System (TMS), wie z.B. Google Tag Manager, welches zur Zeit das
            weitverbreiteste TMS ist.
            <br />
            Es gibt auch andere Tag Management Systeme, wie z.B. Adobe Launch, Tealium, Ensighten oder Matomo (open
            source). Grundsätzlich dienen alle dem selben Zweck und man kann Google Analytics auch mit einem
            Nicht-Google Tag Management System implementieren. Google Tag Manager ist aber das System mit dem
            einfachsten Zugriff und hat viele hilfreiche Ressourcen online verfügbar.
            <br />
            Lies meine Anleitung zum <Link to="/de/google-tag-manager-einrichten">Google Tag Manager Einrichten</Link>, falls Du mehr über GTM lernen möchtest.
          </li>
        </ol>
        <p>
          Enterprises neigen zu den alternativen Systemen unter Anderem wegen Datenschutzbedenken. Google hat diesen
          Anspruch jedoch mit{" "}
          <a href="https://marketingplatform.google.com/about/analytics-360/?hl=de">Google Analytics 360</a> und{" "}
          <a href="https://marketingplatform.google.com/about/tag-manager-360/">Google Tag Manager 360</a> gedeckt und
          bietet dort volles Eigentum der Daten an.
        </p>
        <H as="h2">Google Analytics mit Google Tag Manager implementieren</H>
        <p>
          Zum <strong>Implementieren von Google Analytics via Google Tag Manager (GTM)</strong>, sollte zuerst ein GTM
          Konto erstellt werden.
        </p>
        <ul>
          <li>
            Besuch <a href="https://tagmanager.google.com/?hl=de">tagmanager.google.com</a> und erstell ein neues
            Konto.
          </li>
        </ul>
        <p>
          <ImgScreenshot
            src="google-analytics-einrichten/google-tag-manager-konto-erstellen.png"
            alt="Google Tag Manager Konto erstellen"
          />
        </p>
        <ul>
          <li>
            Zusammen mit dem Konto erstellst du automatisch deinen ersten GTM Container. Wähl als Zielplattform{" "}
            <strong>Web</strong> aus.
          </li>
        </ul>
        <p>
          <ImgContainerFixed width="499px">
            <ImgScreenshot
              src="google-analytics-einrichten/google-tag-manager-container-einrichten.png"
              alt="Google Tag Manager Container erstellen"
            />
          </ImgContainerFixed>
        </p>
        <ul>
          <li>
            Danach erhälst Du das <strong>Google Tag Manager snippet</strong>, welches nun auf jeder Seite der Website
            implementiert werden sollte.
          </li>
        </ul>
        <p>
          <ImgScreenshot
            src="google-analytics-einrichten/google-tag-manager-snippet.png"
            alt="Google Tag Manager code snippet"
          />
        </p>
        <ul>
          <li>
            Der <strong>erste Teil</strong> des Google Tag Manager snippets soll im <code>&lt;head&gt;</code> der
            Seite eingefügt werden. Der <strong>zweite Teil</strong> sollte als erstes Tag im{" "}
            <code>&lt;body&gt;</code> platziert werden.
          </li>
        </ul>
        <p>
          Nachdem der Google Tag Manager code auf allen Seiten eingefügt ist, können wir uns{" "}
          <strong>in GTM einloggen und von dort Google Analytics auf der Webseite implementieren</strong>. Los geht's!
        </p>{" "}
        <ul>
          <li>
            Log Dich bei <a href="https://tagmanager.google.com/?hl=de">tagmanager.google.com</a> ein und klick in der
            Hauptnavigation links auf <strong>Tags</strong> und dann auf <strong>Neu</strong>. Danach slidet die Tag
            Konfiguration ins Bild. Wir können dem Tag erstmal einen Namen geben, zum Beispiel{" "}
            <em>Google Analytics Tracking</em>.
          </li>
          <li>Danach einmal in die Mitte auf das graue Logo, auf die sog. Tag-Typ-Konfiguration, klicken:</li>
        </ul>
        <p>
          <ImgScreenshot
            src="google-analytics-einrichten/google-tag-manager-tag-erstellen.png"
            alt="Google Tag Manager Tag erstellen"
          />
        </p>
        <ul>
          <li>
            Eine Liste mit Tag-Typen slidet danach ins Bild. Wähl{" "}
            <strong>Google Analytics - Universal Analytics</strong>.
          </li>
        </ul>
        <p>
          <ImgContainerFixed width="408px">
            <ImgScreenshot
              src="google-analytics-einrichten/google-tag-manager-tag-typ-auswaehlen.png"
              alt="Google Tag Manager tag typ auswählen"
            />
          </ImgContainerFixed>
        </p>
        <ul>
          <li>
            Nach der Auswahl kommt man in die eigentliche Tag-Konfiguration. Den <strong>Tracking-Typ</strong> auf{" "}
            <strong>Seitenaufruf</strong> eingestellt lassen, da wir tracken wollen, wie oft die Seiten besucht
            werden.
          </li>
          <li>
            Dann unter <strong>Google Analytics Einstellungen</strong> im Dropdown <strong>Neue Variable…</strong>{" "}
            wählen.
          </li>{" "}
        </ul>
        <p>
          <ImgScreenshot
            src="google-analytics-einrichten/google-tag-manager-google-analytics-einstellung-mit-variable.png"
            alt="Google Tag Manager Google Analytics Einstellung mit variable"
          />
        </p>
        <ul>
          <li>
            Jetzt konfigurieren wir die Google Analytics Einstellungsvariable. Zuerst vergeben wir wieder einen Namen,
            z.B. <em>Google Analytics Einstellungen</em>. Danach müssen wir unter <strong>Tracking-ID</strong> die
            Google Analytics Tracking-ID (z.B. <em>UA-141112345-1</em>) einfügen, die wir{" "}
            <a href="#google-analytics-tracking-code">vorher bekommen haben</a>.
          </li>
        </ul>
        <p>
          <ImgContainerFixed width="327px">
            <ImgScreenshot
              src="google-analytics-einrichten/google-tag-manager-google-analytics-einstellungen-variable-konfigurieren.png"
              alt="Google Tag Manager Google Analytics Einstellungsvariable konfigurieren"
            />
          </ImgContainerFixed>
        </p>
        <ul>
          <li>
            Dann oben rechts <strong>Speichern</strong> klicken.
          </li>
          <li>
            Zurück in der <strong>Tag Konfiguration</strong> unten auf das <strong>Trigger</strong>-Feld klicken.
          </li>
          <li>
            Hier <strong>All Pages</strong> auswählen, weil wir möchten, dass das Google Analytics Tag bei allen
            Seitenaufrufen ausgeführt wird.
          </li>
          <li>
            <strong>Speichern</strong> und zurück in der GTM Container-Übersicht oben rechts auf{" "}
            <strong>Senden</strong> klicken. Jetzt warden alle Einstellungen in der ersten Container-Version
            gespeichert. Wir geben dem Container jetzt einen beliebigen <strong>Namen</strong> und klicken auf{" "}
            <strong>Veröffentlichen</strong>. Fertig!
          </li>
          <li>Du hast gerade mit Google Tag Manager Google Analytics implementiert!</li>
          <li>
            {" "}
            Du kannst die ersten Daten nach ca. einer Stunde in den Berichten sehen. Wenn du in Google Analytics auf
            die Echtzeit-Übersicht klickst, kannst du schon überprüfen ob Besucher in Echtzeit getrackt wurden.
          </li>
        </ul>
        <p>
          <ImgScreenshot
            src="google-analytics-einrichten/google-analytics-tracking-echtzeit-tracking.png"
            alt="Google Analytics Echzeit Tracking"
          />
        </p>
        <p>
          Hast Du gemerkt, wie man schnell man Google Analytics theoretisch implementieren könnte? Nachdem Google Tag
          Manager implementiert ist, kann ein Tag in wenigen Minuten installiert werden. Ohne aufwendige Hilfe.
          <br />
          Ich hoffe dies macht Dir die Stärke und Flexibilitet von Tag Management Systemen bewusst.
        </p>
        <H as="h2">Google Analytics Zielvorhaben einrichten</H>
        <p>
          Jetzt, nachdem wir Seitenaufrufe tracken, können wir die ersten Google Analytics Zielvorhaben einrichten.
        </p>
        <p>
          Wahrscheinlich gibt es etwas auf deiner Webseite zu kaufen oder es gibt ein Formular zum ausfüllen.
          Vielleicht gibt es auch einen Newsletter zum Anmelden oder einer andere Art der Konversion.
        </p>
        <p>
          Nach der Konversion landet man normalerweise auf einer <em>Danke-Seite</em>. Hier warden dann die
          Einzelheiten zur Bestellung noch einmal aufgelistet, oder nächste Schritte mitgeteilt.
          <br />
          Geh auf deine Webseite und schau, welche URL bei der <em>Danke-Seite</em> in der Adressleiste des Browsers
          angezeigt wird.
        </p>
        <p>Die URL könnte zum Beispiel so aussehen:</p>
        <p>
          <code>https://www.meineSeite.de/danke.php</code>
        </p>
        <p>
          Für diese Adresse gilt es nun ein Google Analytics Zielvorhaben einzurichten. Das heißt man definiert in
          Google Analytics gewisse URLs damit jeder Seitenaufruf speziell gezählt und dargestellt wird.
        </p>
        <ul>
          <li>
            Öffne den Verwaltungsbereich in Google Analytics. Hier kannst Du verschiedene Einstellungsmöglichkeiten
            zum <strong>Konto</strong>, <strong>Property</strong> und der <strong>Datenansicht</strong> sehen.
          </li>
          <li>
            Klick auf <strong>Zielvorhaben</strong> rechts in der <strong>Datenansicht</strong>.
          </li>
        </ul>
        <p>
          <ImgScreenshot
            src="google-analytics-einrichten/google-analytics-verwaltung-zielvorhaben.png"
            alt="Google Analytics verwaltung zielvorhaben"
          />
        </p>
        <ul>
          <li>
            Klick auf <strong>Neues Zielvorhaben</strong> und wähle danach <strong>benutzerdefiniert</strong>. Danach
            auf <strong>weiter</strong> klicken.
          </li>
          <li>
            Gib dem Zielvorhaben einen <strong>Namen</strong>, z.B. <em>Newsletter Anmeldung</em> oder{" "}
            <em>Bestellformular erhalten</em>. Wähle als <strong>Typ:</strong> <strong>Ziel</strong> aus. Dann{" "}
            <strong>weiter</strong> klicken.
          </li>
          <li>
            Unter <strong>Zielvorhabendetails</strong> können wir nun den <strong>Adresspfad der Danke-Seite</strong>{" "}
            angeben. Wenn wir <em>Ist gleich</em> im Dropdown stehen lassen, muss die <strong>exakte</strong> URL
            angegeben warden. Ich würde davon abraten, da die moisten Danke-Seiten Parameter mit in der URL haben, die
            bei jeder Bestellung anders sind.
          </li>
        </ul>
        <p>
          Das heißt, wenn wir jetzt unseren Pfad <em>/danke.php</em> angeben würden, aber die wirkliche Adresse später
          z.B. <em>/danke.php?q=conv&amp;uid=5373</em> lauten würde, wäre das Zielvorhaben <strong>nicht</strong>{" "}
          ausgelöst worden.{" "}
        </p>
        <p>
          Ich empfehle daher <em>Beginnt mit</em> im Dropdown zu wählen und danach nur den <strong>Adresspfad</strong>{" "}
          <em>/danke.php</em> in das Textfeld einzufügen.
        </p>
        <ul>
          <li>
            Wer außerdem mit <em>Regulären Ausdrücken</em> (<em>Regex Expressions</em>) bekannt ist kann diese hier
            auch benutzen, um den Adresspfad zu matchen.
            <br />
            Wer nicht weiß was das ist: <em>Regex Expressions</em> sind eine fortgeschrittene Form um Text zu filtern.
            <br />
            Ich kann nur empfehlen sich näher damit auseinander zusetzen, wenn man plant mit Daten zu arbeiten. Hier
            kannst du in ein paar Stunden lernen wie man sie benutzt: <a href="https://regexone.com">RegexOne</a>
          </li>
          <li>
            Du kannst dem Zielvorhaben optional auch einen <strong>Wert</strong> zuordnen. Ich würde es empfehlen,
            wenn Du einen Sinvollen Wert für die Konversion ermitteln kannst.
            <br />
            Um den Wert furs Zielvorhaben zu ermitteln gibt es verschiedene Möglichkeiten. Wenn du ein T-shirt für 10€
            verkaufst, ordne <strong>10</strong> als Wert zu.
            <br />
            Bei einem Kontaktformular, könnte man den durchschnittlichen Wert per erhaltenem Formular errechnen. Zum
            Beispiel mag es nach durchschnittlich 20 Kontaktaufnahmen zu einem 100€ Auftrag kommen. In dem Fall ist
            der Durchschnittswert per Zielvorhaben <strong>5</strong>.
          </li>
          <li>
            Du kannst auch einen <em>Trichter</em> bzw. <strong>Funnel einrichten</strong>. Dies macht Sinn, wenn die
            Besucher üblicherweise andere Seiten vor der Konversion besuchen. Dies macht vor Allem Sinn bei mehrseiten
            Formularen, die in Schritte eingeteilt sind.
            <br />
            Durch das Erstellen eines Funnels könnte man später prüfen, ob Besucher den Ausfüllprozess bei einem
            gewissen Schritt abbrechen, bevor aus zur Konversion kommt.
            <br />
            Du fügst die einzelnen Schritte hinzu, indem du für jeden Schritt einen <strong>Namen</strong> und den
            zugehörigen <strong>Adresspfad</strong> angibst.
          </li>

          <li>
            Du must beim <strong>Adresspfad</strong> im Funnel der gleichen Regel folgen, wie oben im Dropdown
            ausgewählt. D.h. wenn <em>Beginnt mit</em> gewählt wurde, muss der gleichen Regel unten bei den
            Funnelregeln gefolgt werden. Das gleiche gilt für <em>Reguläre Ausdrücke</em> oder <em>Ist gleich</em>.
          </li>
          <li>
            Bevor Du auf <strong>Speichern</strong> klickst, würde ich einmal auf{" "}
            <strong>Dieses Zielvorhaben bestätigen</strong> klicken. Falls schon etwas Daten in deinem Google
            Analytics Konto liegen, wird hier geprüft ob die Adresspfade in der Vergangenheit zu Konversionen geführt
            hätten.
            <br />
            Falls Du 0% angezeigt bekommst, hast du entweder einen Fehler im Adresspfad fur’s Zielvorhaben, oder solch
            ein Adresspfad existiert bislang nicht in den Daten.
            <br />
            Hier ist also ein guter Punkt erreicht, um nochmal alle Adresspfade zu prüfen, bevor Du das Zielvorhaben
            speicherst.
          </li>
        </ul>
        <p>
          <ImgScreenshot
            src="google-analytics-einrichten/google-analytics-zielvorhaben-einrichten.png"
            alt="Google Analytics Zielvorhaben konfiguration"
          />
        </p>
        <p>
          Glückwunsch! Du hast gerade deine erstes Zielvorhaben konfiguriert! Damit ist der erste Leistungsindikator
          für die Webseite fertig.
          <br />
          Zielvorhaben sollten für alle zentralen Ziele der Webseite eingerichtet werden.
        </p>
        <p>
          Falls es noch weitere, <em>weiche</em> Konversionen gibt oder diese keine spezifische URL haben, sollte{" "}
          <Link to="/de/event-tracking">
            Ereignistracking (<em>Event Tracking</em>) eingerichtet
          </Link>{" "}
          werden.
          <br />
          Danach können solche Ereignisse auch als Grundlage für Zielvorhaben genutzt werden. Der zu wählende Typ in
          der Zielvorhabenkonfiguration (siehe oben) ändert sich dann zu “Ereignis”. Danach musst Du nur noch die
          Eventparameter (Kategorie, Action, Label) für das jeweilige Ereignis angeben.
        </p>
        <H as="h2" id="tracking-für-kampagnen-einrichten">Tracking für Kampagnen einrichten</H>
        <p>Kampagnentracking ist schon in Google Analytics eingebaut. Man muss nur sog. <Link to="/de/wiki-analytics/utm-parameter-de">UTM Parameter</Link> zu den Links seiner Kampagnen in sozialen Netzwerken oder Emails hinzufügen.</p>
        <p>Es gibt fünf Tracking-Parameter, welche man einfach jedem Link der zur eigenen Seite führt hinzufügt. Google Analytics’ eingebautes Kampagnentracking erkennt diese Parameter automatisch und liest die Daten im Webinterface benutzerfreundlich aus. Außerdem wird jeder Besuch von so einem Link der jeweiligen Kampagne zugeordnet (Attribution).</p>
        <p><strong>Beispiel</strong>: Lasst uns der URL <code>https://www.yoursite.com/campaign</code> die folgenden Parameter hinzufügen:</p>

        <table>
          <thead>
            <tr>
              <th>UTM Parameter</th>
              <th>UTM Wert</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>UTM Source</td>
              <td>Medium</td>
            </tr>
            <tr>
              <td>UTM Medium</td>
              <td>Guest Post</td>
            </tr>
            <tr>
              <td>UTM Campaign</td>
              <td>Spring Sales</td>
            </tr>
            <tr>
              <td>UTM Term</td>
              <td>Stiefel</td>
            </tr>
            <tr>
              <td>UTM Content</td>
              <td>Stiefel im Sommer</td>
            </tr>
          </tbody>
        </table><p>So sieht die selbe URL mit den UTM Parametern aus:</p>
        <code>https://www.yoursite.com/campaign?utm_source=medium&amp;utm_medium=guest-post&amp;utm_campaign=spring-sales&amp;utm_term=stiefel&amp;utm_content=stiefel-im-sommer</code>
        <br /><br />
        <p>Bemerke, dass alle Werte kleingeschrieben und Leerzeichen mit Bindestrichen ersetzt sind.</p>
        <p>Du kannst diesen Link jetzt auf Facebook, Twitter oder Linkedin benutzen und Google Analytics wird alle Klicks zählen und automatisch dieser Kampagne zuschreiben.</p>
        <p>Siehst Du die fünf UTM parameter in der Webadresse? Du musst die Paramter nicht mühselig selber erstellen. Es gibt <a href="https://ga-dev-tools.appspot.com/campaign-URL-builder/" target="_blank">UTM Builder Tools</a> die Dir die Arbeit abnehmen.<br />Du musst nur den Link für die Kampagne und die Werte für die Parameter angeben. Das UTM Tool gibt danach den fertigen Kampagnen-Link mit allen UTM Parametern aus.</p>


        <H as="h2">Bewährte Einstellungen für Google Analytics</H>
        <p>Jetzt folgt noch eine Liste mit Tipps um Google Analytics (GA) einzustellen. Ich befolge dabei jeden Punkt rigoros wenn ich GA konfiguriere. Diese Tipps sind nicht nur gut zu beachten, sondern verbessern die Qualität des Tracking-Setups ungemein.</p>
        <H as="h3" id="eigene-webseitenbesuche-von-google-analytics-ausschließen">Eigene Webseitenbesuche von Google Analytics ausschließen</H>
        <p>Jetzt wo wir Google Analytics aufgesetzt haben, müssen noch unsere eigenen Besuche ausgeschlossen werden.</p>
        <p>Unsere Besuche weisen ein anderes Verhalten auf und verzerren die Daten. Vor Allem auf Websites mit wenigen Besuchern.</p>
        <p>Um die eigenen Besuche auszuschließen, können wir unsere IP Adresse mit einem Filter ausschließen.</p>
        <p><strong>Google Analytics Filter</strong> Funktionalität kann auch in Google Tag Manager eingestellt werden (oft bevorzuge ich das sogar). Die Filter in GA sind jedoch einfach und schnell einzustellen und gelangen zu dem gleichen Ergebnis.</p>
        <ul>
          <li>Zu den Filtereinstellungen gelangen wir durch die Verwaltung unter <strong>Datenansicht</strong> &gt; <strong>Filter</strong>.</li>
          <li>Gib einen Namen für den Filter an und wähle <strong>benutzerdefiniert</strong>.</li>
          <li>Wähle <strong>Ausschließen</strong> als Filterart und gib <strong>IP-Adresse</strong> im Filterfeld an.</li>
          <li>Danach musst Du nur noch die statische IP im Filtermuster angeben.</li>
        </ul>

        <ImgContainerFixed width="399px">
          <ImgScreenshot
            src="google-analytics-einrichten/filter-einstellungen-IP-in-google-analytics-ausschließen.png"
            alt="Eigenen Traffik von Google Analytics ausschließen"
            className="article-img"
          />
        </ImgContainerFixed>

        <H as="h3" id="adresspfade-auf-kleingeschrieben-transformieren">Adresspfade auf kleingeschrieben transformieren</H>
        <p>Ein anderer Tipp ist die Adresspfade der URLs automatisch auf kleingeschrieben zu tranformieren.</p>
        <p>Die Erfahrung zeigt, das irgendwann immer mal Fehler passieren und durch irgendwelche Versehen die gleichen URLs in verschiedenen Schreibweisen in Google Analytics auftauchen. Dafür kann es viele Ursachen geben.</p>
        <p>Das Problem ist, das obwohl die Webseite die selbe ist, Google Analytics zwei verschiedene URLs erhält (auch wenn es nur die Schreibweise ist) und die Daten daher <strong>nicht</strong> zusammenfügt, sondern jede Adresse als eigene Seite behandelt.</p>
        <p>Das Ergebnis ist, dass wenn man die Seite analysieren möchte, ein Teil der Daten unter anderen Adressen gepseichert ist und es kompliziert wird wenn man wirklich alle Besuche einer Seite analysieren möchte.</p>
        <p>Die Lösung: Adresspfade automatisch auf kleingeschrieben ändern.</p>
        <ul>
          <li>Um die Adresspfade auf kleingeschrieben zu ändern, besuche die Google Analytics verwaltung und klicke auf <strong>Filter</strong> in der Datenansicht.</li>
          <li>Erstelle einen <strong>neuen Filter</strong> mit einem Namen und wähle <strong>Benutzerdefiniert</strong></li>
          <li>Danach <strong>Kleinschreibung</strong> anklicken und <strong>Anforderungs-URI</strong> im Menü wählen.</li>
          <ImgContainerFixed width="362px">
            <ImgScreenshot
              src="google-analytics-einrichten/filter-einstellungen-kleinschreibung.png"
              alt="Adresspfade in Google Analytics auf kleingeschrieben transormieren"
              className="article-img"
            />
          </ImgContainerFixed><br />
          <li><strong>Speichern</strong>. Das war’s schon. Einfache Lösung.</li>
        </ul>

        <H as="h3">Google Analytics Struktur für Datenansicht</H>
        <p>In einer <em>Datenansicht</em> finden wir alle Google Analytics Berichte. Wenn ein Filter für eine Datenansicht eingestellt wird (wie im obigen Beispiel), nehmen alle Berichte die gefilterten Daten als Ausgangspunkt.</p>
        <p>Die Struktur der Datenansichten sollte die Struktur des Unternehmens darstellen, d.h. wenn die Zielmärkte nach Ländern oder Regionen eingeteilt werden, sollten Datenansichten der selben Einteilung folgen. Wenn dann später Dashboards oder Reports fabriziert werden, müssen keine Daten mehr umgruppiert werden.</p>
        <p>Eine andere Orientierung kann die Struktur für Budgets und Marketingaktivitäten sein, da diese oft auch regional oder multinational geplant werden.</p>
        <p>Du kannst bist zu 25 Datenansichten in einem Property anlegen. D.h. wenn man von Zielmärkten Marketingaktivitäten ausgeht sind viele Strukturen möglich. Folgend daher ein paar Beispiele:</p>
        <H as="h4" >Beispiel: Datenansicht für lokale Webseiten</H>
        <p>1.0 Master<br />
          2.0 Internal Traffic<br />
          3.0 Raw Data</p>
        <p>Meistens werden mind. 3 Datenansichten per Property angelegt. Diese könnten nach Belieben auch auf das Land oder das Gerät spezifiziert werden. Üblich sind auch weitere Datenansichten für Development oder Staging Umgebungen der Webseite.</p>
        <H as="h4" >Beispiel: Datenansicht für multinationale Webseiten</H>
        <p>1.1 Master (US)<br />
          1.2 Master (UK)<br />
          1.3 Master (DE)<br />
          1.4 Master (FR)<br />
          1.5 Master (ES)<br />
          1.6 Master (MX)<br />
          1.7 Master (RUS)<br />
          2.0 Internal Traffic<br />
          3.0 Raw Data</p>
        <H as="h4" >Beispiel: Datenansicht für Regionen</H>
        <p>1.0 Master (NA)<br />
          1.1 Master (EMEA)<br />
          1.2 Master (LATAM)<br />
          1.3 Master (APEC)<br />
          2.0 Internal Traffic<br />
          3.0 Raw Data</p>
        <p>Die <em>Master</em> Datenansicht ist die primäre Ansicht für die Datenanalyse und schließt alle unternhmenseigene Webseitenaufrufe aus.</p>
        <p><em>Internal Traffic</em> wiederum enthält nur die unternehmenseigenen Webseitenaufrufe. Diese Ansicht kann z.B. für Tests oder Debugging genutzt werden. Sie wird mit einem Filter zum Einschließen von Datenquellen erstellt, welches bedeutet, dass alle anderen Datenquellen ausgeschlossen werden.</p>
        <p>Am Anfang erstellt Google Analytics einen Standardnamen für jede Datenansicht. Behalte die Ansicht ruhig bei, aber ändere den Namen zu <em>Raw Data</em>, den obigen Beipielen folgend.<br />
          Eine Raw Data Ansicht ohne jegliche Filter ist wichtig, um alles einzufangen was gesendet wird. Jeder Filter der ansonten gesetzt wird, kann nicht wieder rückgängig gemacht werden. Somit vermeiden wir Datenverlust und können im Zweifelsfall Daten hier immer die ungefilterten Daten betrachten.<br />
          Um den Namen zu ändern besuche einfach <b>Verwaltung &gt; Datenansicht &gt; Einstellungen der Datenansicht</b>.</p>
        <p>Meistens konzentrieren Unternehmen ihre Marektingaktivitäten jedoch basierend auf einzelne Länder. Daher ist dies in den meisten Fällen die Devise und Filter schließen Besuche von diesen Ländern ein (und alle anderen Besuche aus).</p>
        <p>Theoretisch können auch Datenansichten per Kanal (organisch, paid, direkt oder soziale Netzwerke) oder Gerät (Desktop, Handy oder Tablet) aufgesetzt werden. Ich benutze für solche Filtrierungen jedoch <em>Google Analytics Segmente</em>. Es gibt jedoch keinen Grund gewisse Filtrierungen schon auf Ansichtsebene durchzuführen, insofern sie flächendeckend benötigt werden. Ich mag es jedoch die Anzahl der Ansichten klein zu halten, da die Benutzeroberfläche dadurch übersichtlicher bleibt. Danach werde ich dann ggf. mit Segmenten etwas unordentlicher, aber sorum ist es mit lieber, da Segmente nicht so prominent gezeigt werden.</p>
        <p>Ein anderer <b>Tipp</b> ist alle Ansichten so früh wie möglich zu erstellen. Ansichten zeigen nämlich nur Daten seit dem Zeitpunkt der Erstellung und nicht rückwirkend. Falls man weiter zurück in die Vergangenheit schauen möchte ist steht immer noch die Raw Data Ansicht zur Verfügung. Ungewollte Daten können dann noch immer mit Segmenten herausgefiltert werden. Somit erhält man seine Einsichten, vermeidet es aber die Daten mit Filtern ändern zu müssen, da Segmente oft genauso gut funktionieren.</p>
        <p>Noch ein <b>Tipp</b>: Wenn viele Ansichten die selben Filter nutzen, kannst du erst eine Ansicht mit dem Filter erstellen und sie dann duplizieren. Andere Filter die spezifisch für eine andere Ansicht sind werden dann manuel hinzugefügt.<br />
          Pass aber auf den ursprünglichen Filter nicht zu ändern, da sonst die Duplikate auch die Änderungen übernehmen.</p>
        <H as="h3">Benachrichtigungen für Google Analytics</H>
        <p>Eine andere Praxis ist sich wichtige automatisierte Benachrichtigungen einzustellen. Ich benutze Benachrichtigungen z.B. um bei einem starken Fall von Besuchen oder sehr langsamen Server-Antworten eine Email als Hinweis zu erhalten. Bei einem frühen Hinweis kann ggf. größerer Datenverlust vermieden werden.</p>
        <p>Um Benachrichtigungen in Google Analytics einzustellen, gehe zu <b>Anpassung</b> in der linken Navigationsleiste und klick auf <b>Benutzerdefinierte Benachrichtigungen</b>.</p>
        <ImgContainerFixed width="366px">
          <ImgScreenshot
            src="google-analytics-einrichten/benachrichtigungen-in-ga.png"
            alt="benutzerdefinierte Benachrichtigungen in Google Analytics"
            className="article-img"
          />
        </ImgContainerFixed><br />
        <p>Nach einem weiteren Klick auf <b>Benutzerdefinierte Benachrichtigungen verwalten</b> und <b>Neue Benachrichtigung</b> können wir einen neuen Alarm erstellen.</p>
        <H as="h4" >Benachrichtigung bei Abnahme des Traffics</H>
        <ul>
          <li>Um einen Alarm für Traffic Abnahmen zu erhalten, können wir hier <b>Traffic Rückgang Woche zu Woche</b> angeben und den Zeitraum auf <b>Woche</b> einstellen. Somit werden nur ganze Wochen miteinander verglichen. Wir vermeiden dadurch, dass Tagesschwankungen die Benachrichtigung auslöst.</li>
          <li>Setze auch einen Haken, damit eine <b>Email</b> an Dich gesendet wird.</li>
          <li>Die Bedingungen gelten für <b>Alle Zugriffe</b> und es soll eine Banchrichtigung gesendet werden sobald <b>Sitzungen</b> um mehr als 35% abnehmen, im vergleich mit der vorherigen Woche.</li>
          <ImgScreenshot
            src="google-analytics-einrichten/ga-benachrichtigung-traffic-abnahme.png"
            alt="Benachrichtigung bei Traffic Abnahme"
          />
          <br />
          <li>Schließe die Konfiguration ab mit einem Klick auf <b>Benachrichtigung speichern</b>.</li>
        </ul>
        <p>In einem weiteren Beispiel können wir uns benachrichtigen lassen, falls unser Server überlastet wird.</p>
        <H as="h4" >Benachrichtigung bei langsamen Serverantworten</H>
        <ul>
          <li>Klick auf <b>Neue Benachrichtigung</b> und gib einen Namen an, z.B. <b>Serverantwortzeit Tag zu Tag</b>.</li>
          <li>Wähle als Zeitraum <b>Tag</b>.</li>
          <li>Häkchen fürs Email senden setzen.</li>
          <li>Die Bedingungen gelten wieder für <b>Alle Zugriffe</b>.</li>
          <li>Wir sollen benachrichtigt werden soblad die <b>durchschnittliche Serverantwortzeit</b> um mehr als 300% zunimmt, im Vergleich zum Vortag.</li>
          <ImgScreenshot
            src="google-analytics-einrichten/ga-benachrichtigung-serverantwort.png"
            alt="Google Analytics Alarm bei langsamen Server"
          />
          <br />
          <li>Danach <b>speichern</b> klicken.</li>
        </ul>
        <p>Jetzt kannst Du sicher sein eine Email zu erhalten, wenn der Server anfängt ausgelastet zu sein - ein Problem das lange übersehen werden kann.</p>

      </MainContent>
    </React.Fragment>

  </Layout>
);

export default GoogleAnalyticsEinrichten;
